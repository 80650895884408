import * as React from 'react'
import { Address } from '../domain/company'

export interface AddressDetailsProps {
  address: Address
}

const AddressDetails = (props: AddressDetailsProps) => {
  const a = props.address
  return <span>{`${a.street} ${a.houseNumber}, ${a.zip} ${a.city}, ${a.countryCode}`}</span>
}

export default AddressDetails
