import * as React from 'react'
import { MoneyAmount } from '../../domain/common'
import { FormattedNumber } from 'react-intl'

export interface MoneyProps {
  amount: MoneyAmount
}

const Money = (props: MoneyProps) => (
  <FormattedNumber value={props.amount.amount} style="currency" currency={props.amount.currency} />
)

export default Money
