/** @jsx jsx */
import * as React from 'react'
import { css, jsx } from '@emotion/core'

import { Layout, Menu, Row, Col, Select } from 'antd'
import LaempeLogo from './LaempeLogo'
import * as colors from '../colors'
import LogoutButton from './LogoutButton'
import { useIntl } from 'react-intl'

const { Header, Content } = Layout

type HeaderTheme = 'customer' | 'backoffice'

interface LanguageSelectProps {
  defaultValue?: string
  languages?: string[]
  onLanguageChange?: (language: string) => void
}

const LanguageSelect = (props: LanguageSelectProps) => {
  const intl = useIntl()
  const Option = Select.Option
  return (
    <Select defaultValue={props.defaultValue} size="small" onChange={props.onLanguageChange}>
      {props.languages?.map(l => (
        <Option key={l} value={l}>
          {intl.formatMessage({ id: `language.${l}` })}
        </Option>
      ))}
    </Select>
  )
}
export interface MenuItem {
  value: React.Key
  label: React.ReactNode
}

export interface LaempeLayoutProps {
  children?: React.ReactNode
  menu?: MenuItem[]
  theme?: HeaderTheme
  defaultSelectedMenuItem?: string
  defaultLanguage?: string
  languages?: string[]
  onSelectMenuItem?: (value: React.Key) => void
  onLanguageChange?: (language: string) => void
  showLogout?: boolean
}

const height = 36.5

const LaempeLayout = (props: LaempeLayoutProps) => {
  const hasMenu = props.menu && props.menu.length > 0
  const headerHeight = hasMenu ? 180 : 120

  const showLanguages = props.languages && props.languages.length > 1

  const fillerSpan = 23 - (showLanguages ? 1 : 0) - (props.showLogout ? 1 : 0)

  return (
    <Layout>
      <Header
        css={css`
          height: ${headerHeight}px;
          padding: 0px;
          background-color: white;
        `}
      >
        <Row
          align="middle"
          css={css`
            width: 100%;
            background: ${props.theme === 'backoffice' ? colors.grey : colors.yellow};
            height: ${height}px;
          `}
        >
          <Col span={fillerSpan} />
          {showLanguages && (
            <Col
              span={2}
              css={css`
                line-height: 0px;
              `}
            >
              <LanguageSelect
                defaultValue={props.defaultLanguage}
                languages={props.languages}
                onLanguageChange={props.onLanguageChange}
              />
            </Col>
          )}
          {props.showLogout && (
            <Col
              span={1}
              css={css`
                line-height: 0px;
              `}
            >
              <LogoutButton />
            </Col>
          )}
        </Row>
        <Row
          align="middle"
          css={css`
            height: ${height * 2}px;
            box-shadow: 0 4px 6px -6px #222;
            padding-top: 10px;
            margin-bottom: 5px;
          `}
        >
          <Col>
            <Row align="middle">
              <Col
                span={6}
                css={css`
                  margin-left: 20px;
                `}
              >
                <LaempeLogo />
              </Col>
            </Row>
          </Col>
        </Row>
        {props.menu && hasMenu && (
          <Menu
            mode="horizontal"
            defaultSelectedKeys={props.defaultSelectedMenuItem ? [props.defaultSelectedMenuItem] : undefined}
            onSelect={({ key }) => props.onSelectMenuItem?.(key)}
            css={css`
              height: 10px;
              border-style: none;
            `}
          >
            {props.menu.map(({ value, label }) => (
              <Menu.Item key={value}>{label}</Menu.Item>
            ))}
          </Menu>
        )}
      </Header>
      {props.children && (
        <Content style={{ padding: '0 20px', background: 'white' }}>
          <div className="content">{props.children}</div>
        </Content>
      )}
    </Layout>
  )
}

export default LaempeLayout
