import * as React from 'react'
import { Locale as DateLocale } from 'date-fns'
import de from 'date-fns/locale/de'

export interface LocaleContextProps {
  locale: string
  dateLocale: DateLocale
}

export const LocaleContext = React.createContext<LocaleContextProps>({ locale: 'de-DE', dateLocale: de })

export const LocaleContextProvider = LocaleContext.Provider
export const LocaleContextConsumer = LocaleContext.Consumer
