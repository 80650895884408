import * as React from 'react'
import { format, parseISO } from 'date-fns'
import { LocaleContext } from '../LocaleContext'

export interface DateTimeProps {
  value: Date | string
  format?: 'date' | 'date-time'
}
const DateTime = (props: DateTimeProps) => {
  const { dateLocale } = React.useContext(LocaleContext)
  const d = typeof props.value === 'string' ? parseISO(props.value) : props.value
  return (
    <React.Fragment>
      {format(d, props.format === 'date-time' ? 'Pp' : 'P', { locale: dateLocale })}
    </React.Fragment>
  )
}

export default DateTime
