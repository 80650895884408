import * as React from 'react'
import { KeycloakInstance } from 'keycloak-js'

interface AuthContextProps {
  keycloak: KeycloakInstance | undefined
}

export const AuthContext = React.createContext<AuthContextProps>({ keycloak: undefined })

export const AuthConsumer = AuthContext.Consumer

export const AuthProvider = (props: React.ProviderProps<AuthContextProps>) => {
  const authenticated = props.value.keycloak?.authenticated ?? false
  React.useEffect(
    () => {
      if (!props.value.keycloak || !authenticated) {
        return
      }
      props.value.keycloak.updateToken(5).catch(() => {
        console.warn('Could not update token!')
        props.value.keycloak?.login()
      })
    },
    // eslint-disable-next-line
    [authenticated]
  )

  return <AuthContext.Provider value={props.value}>{props.children}</AuthContext.Provider>
}
