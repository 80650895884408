import * as React from 'react'
import { MoneyAmount } from '../../domain/common'
import { Statistic } from 'antd'
import { useIntl } from 'react-intl'

export interface MoneyAmountInfoProps {
  title?: React.ReactNode
  amount: MoneyAmount
  type?: 'normal' | 'warning' | 'problem'
  size?: 'normal' | 'small' | 'big'
}

const MoneyAmountInfo = (props: MoneyAmountInfoProps) => {
  const intl = useIntl()
  const color = props.type === 'warning' ? '#ffa400' : props.type === 'problem' ? '#f32121' : 'black'
  const size = props.size === 'small' ? '14px' : props.size === 'big' ? '34px' : '24px'

  return (
    <Statistic
      title={props.title}
      value={intl.formatNumber(props.amount.amount, {
        style: 'currency',
        currency: props.amount.currency,
      })}
      valueStyle={{
        color: color,
        fontSize: size,
      }}
    />
  )
}

export default MoneyAmountInfo
