import 'antd/dist/antd.css'
import en from './translations/en.json'
import de from './translations/de.json'

const messages = {
  en,
  de,
}

export { default as LaempeLogo } from './components/LaempeLogo'
export { default as LaempeLayout, LaempeLayoutProps } from './components/LaempeLayout'
export { AuthContext, AuthConsumer, AuthProvider } from './components/AuthContext'
export { default as LogoutButton, LogoutButtonProps } from './components/LogoutButton'
export { messages }
export { default as UserDetails } from './components/UserDetails'
export { default as AddressDetails } from './components/AddressDetails'
export { default as LocalDateTime } from './components/LocalDateTime'
export { default as UpdateInfo } from './components/UpdateInfo'
export {
  LocaleContext,
  LocaleContextProps,
  LocaleContextConsumer,
  LocaleContextProvider,
} from './components/LocaleContext'
export { isDefined } from './utils/type-assertions'
export { parseLocale, mergeMessages } from './utils/locale'
export { Updateable, Creatable, UUID, Millimeter, MoneyAmount } from './domain/common'
export { Company, Address, CompanyAddress, CompanyAddressType } from './domain/company'
export { OnBoarding, OnBoardingStatus } from './domain/on-boarding'
export { User } from './domain/user'
export { Locale, Messages, MessagesByLanguage, currencyByCountry, defaultLocale } from './domain/locale'
export { FileDetails } from './domain/file'
export {
  LayerThickness,
  Order,
  OrderPosition,
  OrderStatus,
  PrintConfigV10,
  Quote,
  AcceptedQuote,
  TemporaryQuote,
} from './domain/order'
export { useAccessToken } from './components/hooks'
export { loremIpsum } from './utils/lorem'
export { default as MoneyAmountInfo, MoneyAmountInfoProps } from './components/money/MoneyAmountInfo'
export { default as Money, MoneyProps } from './components/money/Money'
export { default as DateTime, DateTimeProps } from './components/date/DateTime'
