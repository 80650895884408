import { defaultLocale as theDefault, Locale, Messages, MessagesByLanguage } from '../domain/locale'
import { isDefined } from './type-assertions'

export const parseLocale = (locale: string, defaultLocale: Locale = theDefault): Locale => {
  if (!isDefined(locale)) {
    return defaultLocale
  }
  const parts = locale.split('_')
  if (parts.length < 2) {
    return defaultLocale
  }
  return {
    locale,
    language: parts[0],
    countryCode: parts[1],
    variant: parts[2],
  }
}

export const mergeMessages = (...messages: MessagesByLanguage[]) => {
  let result: MessagesByLanguage = {}
  for (let message of messages) {
    for (let [language, translations] of Object.entries(message)) {
      if (!result[language]) {
        result[language] = {} as Messages
      }
      result[language] = { ...result[language], ...translations }
    }
  }
  return result
}
