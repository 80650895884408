import * as React from 'react'
import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import { AuthContext } from './AuthContext'
import { FormattedMessage } from 'react-intl'
import { PoweroffOutlined } from '@ant-design/icons'

export interface LogoutButtonProps extends ButtonProps {
  showText?: boolean
  color?: string
}

const LogoutButton = (props: LogoutButtonProps) => {
  const { keycloak } = React.useContext(AuthContext)

  const onLogout = () => keycloak?.logout()
  const color = props.color ?? 'white'

  return (
    <Button
      size="middle"
      type="link"
      icon={<PoweroffOutlined style={{ color, fontWeight: 'bold', fontSize: '25px', paddingRight: '5px' }} />}
      onClick={onLogout}
      {...props}
    >
      {props.showText && <FormattedMessage id="logout" />}
    </Button>
  )
}

export default LogoutButton
