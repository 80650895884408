/** @jsx jsx */
// @ts-ignore
import * as React from 'react'
import { css, jsx } from '@emotion/core'

export interface LaempeLogoProps {
  height?: number
}

const st0 = css`
  fill: #545656;
`
const st1 = css`
  fill: #ffcc00;
`
const st2 = css`
  fill: #1d1d1b;
`
const st3 = css`
  fill: #e30613;
`
const st4 = css`
  fill: #fccf00;
`

const LaempeLogo = (props: LaempeLogoProps) => (
  <svg version="1.1" x="0px" y="0px" viewBox="0 0 453.5 80.3" style={{ height: props.height ?? '40px' }}>
    <title>laempe_sinto_standard</title>
    <path
      css={st0}
      d="M147,47.4h-37.2c-5.6,0-10.2-4.6-10.2-10.2V0h7.7v37.1c0,1.4,1.1,2.5,2.5,2.5c0,0,0,0,0,0H147L147,47.4z"
    />
    <path
      css={st0}
      d="M387,0h-36.4v47.4h7.7V27.2H387c5.6,0,10.2-4.6,10.2-10.2v-6.8C397.1,4.6,392.6,0,387,0z M389.4,17
	c0,1.4-1.1,2.5-2.5,2.5h-28.7V7.7H387c1.4,0,2.5,1.1,2.5,2.5V17z"
    />
    <path
      css={st0}
      d="M327.4,0h-42.7c-5.6,0-10.2,4.6-10.2,10.2v37.2h7.7V10.2c0-1.4,1.1-2.5,2.5-2.5c0,0,0,0,0,0h17.9v39.6h7.7V7.7
	h17.2c1.4,0,2.5,1.1,2.5,2.5v37.2h7.7V10.2C337.7,4.6,333.1,0,327.4,0z"
    />
    <path
      css={st0}
      d="M192.4,0h-26.5c-5.6,0-10.2,4.6-10.2,10.2v37.2h7.7V26.9h31.5v20.5h7.7V10.2C202.6,4.6,198,0,192.4,0z
	 M163.4,19.2v-9c0-1.4,1.1-2.5,2.5-2.5c0,0,0,0,0,0h26.5c1.4,0,2.5,1.1,2.5,2.5c0,0,0,0,0,0v9H163.4z"
    />
    <polygon
      css={st0}
      points="262.9,7.7 262.9,0 216.2,0 216.2,47.4 262.7,47.4 262.7,39.7 223.9,39.7 223.9,26.9 255.2,26.9
	255.2,19.2 223.9,19.2 223.9,7.7 "
    />
    <polygon
      css={st0}
      points="453.5,7.7 453.5,0 406.8,0 406.8,47.4 453.3,47.4 453.3,39.7 414.5,39.7 414.5,26.9 445.8,26.9
	445.8,19.2 414.5,19.2 414.5,7.7 "
    />
    <path css={st1} d="M76,60.5H20.8c-2.9,0-5.2-2.4-5.3-5.3V0H0v57.5c0,10.1,8.2,18.4,18.4,18.4H76V60.5z" />
    <path
      css={st2}
      d="M415.2,66.4c-0.8-0.3-1.7-0.5-2.6-0.5c-0.8,0-1.7,0.3-1.7,1.3c0,1.9,5.1,0.7,5.1,5c0,2.8-2.3,3.9-4.7,3.9
	c-1.1,0-2.2-0.2-3.3-0.5L408,73c0.9,0.5,1.9,0.8,2.9,0.8c0.7,0,1.9-0.3,1.9-1.5c0-2.3-5.1-0.7-5.1-5c0-2.5,2.1-3.7,4.4-3.7
	c1.1,0,2.1,0.2,3.2,0.4L415.2,66.4z"
    />
    <path css={st2} d="M417.5,60h2.9v3h-2.9V60z M417.5,63.9h2.9v11.9h-2.9V63.9z" />
    <path
      css={st2}
      d="M422.5,63.9h2.8v1.6l0,0c0.8-1.2,2.2-2,3.7-1.9c2.7,0,3.8,2,3.8,4.6v7.5H430v-6.3c0-1.5,0-3.4-1.9-3.4
	c-2.1,0-2.6,2.4-2.6,3.9v5.8h-2.9L422.5,63.9z"
    />
    <path
      css={st2}
      d="M435.7,66.1h-2.2v-2.3h2.2v-2.4l2.9-1v3.4h2.6v2.3h-2.6v5.5c0,1,0.3,2,1.4,2c0.5,0,0.9-0.1,1.3-0.3l0.1,2.4
	c-0.7,0.2-1.4,0.3-2.2,0.3c-2.3,0-3.6-1.5-3.6-3.8L435.7,66.1z"
    />
    <path
      css={st2}
      d="M447.6,63.6c3.3,0,5.9,2.4,5.9,6.3c0,3.4-2.2,6.1-5.9,6.1s-5.9-2.7-5.9-6.1C441.8,66,444.3,63.6,447.6,63.6z
	 M447.6,73.6c2.3,0,2.8-2.1,2.8-4.1c0-1.8-0.9-3.5-2.8-3.5s-2.8,1.8-2.8,3.5C444.8,71.5,445.4,73.6,447.6,73.6L447.6,73.6z"
    />
    <path css={st3} d="M391.8,71.6c0,0-3.6,7.8-12.1,7.2C385.9,82.1,392.6,78.1,391.8,71.6z" />
    <path css={st3} d="M391.5,71.4c-9.5,7.3-17,2.5-18.5-1.2C373.8,79.3,385.2,81.2,391.5,71.4z" />
    <path css={st3} d="M391.3,71.1c-13.1,4.5-18.7-5.1-16.2-10.5C368.3,68.9,378.5,80,391.3,71.1z" />
    <path css={st3} d="M391.1,70.8c-12.5,1.2-16.7-10.2-8.3-14.7C370,57.8,374.8,75.9,391.1,70.8z" />
    <path css={st3} d="M391,70.5c-10.2-1.3-11.4-11.4-3.6-14.8C376,56.9,376.8,70.9,391,70.5z" />
    <path css={st3} d="M383,64.4c0,0,3.5-7.9,12.1-7.2C388.8,53.9,382.1,57.9,383,64.4z" />
    <path css={st3} d="M383.2,64.6c9.5-7.3,17-2.5,18.5,1.2C400.9,56.7,389.5,54.8,383.2,64.6z" />
    <path css={st3} d="M383.4,64.9c13.1-4.5,18.7,5.1,16.2,10.5C406.4,67.1,396.2,56,383.4,64.9z" />
    <path css={st3} d="M383.5,65.2c12.5-1.2,16.7,10.2,8.3,14.7C404.7,78.2,399.9,60.1,383.5,65.2z" />
    <path css={st3} d="M383.7,65.5c10.2,1.3,11.4,11.4,3.6,14.8C398.8,79.1,397.9,65,383.7,65.5z" />
    <path
      css={st0}
      d="M105.6,71.1v-1.4h5v4.4c-0.7,0.6-1.5,1.1-2.4,1.4c-0.8,0.3-1.7,0.5-2.5,0.5c-1.1,0-2.2-0.2-3.2-0.8
	c-0.9-0.5-1.7-1.2-2.2-2.2c-0.5-1-0.7-2.1-0.7-3.2c0-1.1,0.2-2.2,0.7-3.3c0.4-1,1.2-1.7,2.1-2.2c1-0.5,2.1-0.8,3.1-0.7
	c0.8,0,1.6,0.1,2.3,0.4c0.6,0.2,1.2,0.6,1.6,1.2c0.4,0.6,0.7,1.3,0.9,2l-1.4,0.4c-0.1-0.5-0.4-1-0.7-1.4c-0.3-0.4-0.7-0.7-1.1-0.8
	c-0.5-0.2-1.1-0.3-1.6-0.3c-0.6,0-1.3,0.1-1.9,0.3c-0.5,0.2-0.9,0.5-1.3,0.9c-0.3,0.3-0.6,0.7-0.8,1.2c-0.3,0.8-0.5,1.6-0.5,2.4
	c0,0.9,0.2,1.8,0.5,2.7c0.3,0.7,0.9,1.2,1.6,1.6c0.7,0.3,1.5,0.5,2.2,0.5c0.7,0,1.4-0.1,2-0.4c0.5-0.2,1-0.5,1.5-0.8v-2.2
	L105.6,71.1z"
    />
    <path css={st0} d="M118,75.8V63.9h1.6v10.5h5.9v1.4H118z" />
    <path
      css={st0}
      d="M132,70c-0.1-1.7,0.5-3.4,1.6-4.6c1.1-1.1,2.6-1.7,4.1-1.7c1.1,0,2.1,0.3,3,0.8c0.9,0.5,1.6,1.3,2,2.2
	c0.5,1,0.7,2.1,0.7,3.2c0,1.1-0.2,2.2-0.7,3.2c-0.5,0.9-1.2,1.7-2.1,2.2c-0.9,0.5-1.9,0.7-2.9,0.7c-1.1,0-2.1-0.3-3-0.8
	c-0.9-0.5-1.6-1.3-2-2.2C132.2,72.1,132,71,132,70z M133.6,70c-0.1,1.2,0.3,2.5,1.2,3.4c1.5,1.6,4,1.7,5.7,0.2
	c0.1-0.1,0.1-0.1,0.2-0.2c0.8-1,1.2-2.3,1.1-3.5c0-0.9-0.1-1.7-0.5-2.5c-0.3-0.7-0.8-1.3-1.4-1.7c-0.6-0.4-1.4-0.6-2.1-0.6
	c-1.1,0-2.1,0.4-2.9,1.1C133.9,67.2,133.5,68.6,133.6,70L133.6,70z"
    />
    <path
      css={st0}
      d="M150.5,75.8V63.9h4.5c0.7,0,1.5,0.1,2.2,0.4c0.5,0.2,1,0.6,1.3,1.1c0.3,0.5,0.5,1,0.5,1.6c0,0.5-0.1,1-0.4,1.4
	c-0.3,0.5-0.7,0.9-1.3,1.1c0.7,0.2,1.2,0.6,1.7,1.1c0.4,0.5,0.6,1.2,0.6,1.8c0,0.5-0.1,1.1-0.4,1.6c-0.2,0.4-0.5,0.8-0.9,1.1
	c-0.4,0.3-0.8,0.5-1.3,0.6c-0.6,0.1-1.3,0.2-1.9,0.2L150.5,75.8z M152,68.9h2.6c0.5,0,1,0,1.5-0.1c0.4-0.1,0.7-0.3,0.9-0.6
	c0.2-0.3,0.3-0.6,0.3-1c0-0.4-0.1-0.7-0.3-1c-0.2-0.3-0.5-0.5-0.8-0.6c-0.6-0.1-1.2-0.2-1.8-0.2h-2.4L152,68.9z M152,74.4h3
	c0.4,0,0.7,0,1.1-0.1c0.3-0.1,0.6-0.2,0.9-0.3c0.3-0.2,0.5-0.4,0.6-0.7c0.2-0.3,0.2-0.7,0.2-1c0-0.4-0.1-0.8-0.3-1.2
	c-0.2-0.3-0.6-0.6-1-0.7c-0.6-0.2-1.2-0.2-1.7-0.2H152L152,74.4z"
    />
    <path
      css={st0}
      d="M165.5,75.8l4.6-11.9h1.7l4.9,11.9h-1.8l-1.4-3.6h-5l-1.3,3.6H165.5z M168.9,70.9h4l-1.2-3.3
	c-0.4-1-0.7-1.8-0.9-2.5c-0.2,0.8-0.4,1.5-0.6,2.3L168.9,70.9z"
    />
    <path css={st0} d="M183,75.8V63.9h1.6v10.5h5.9v1.4H183z" />
    <path
      css={st0}
      d="M207.7,75.8V63.9h4.5c0.6,0,1.2,0,1.8,0.1c0.5,0.1,1,0.3,1.5,0.6c0.4,0.3,0.8,0.7,1,1.1c0.7,1.4,0.4,3-0.6,4.2
	c-0.7,0.7-1.8,1-3.5,1h-3v4.8L207.7,75.8z M209.3,69.5h3.1c0.8,0.1,1.5-0.1,2.2-0.6c0.4-0.4,0.7-1,0.6-1.6c0-0.5-0.1-0.9-0.4-1.3
	c-0.2-0.3-0.6-0.6-1-0.7c-0.5-0.1-1-0.1-1.5-0.1h-3L209.3,69.5z"
    />
    <path
      css={st0}
      d="M222.6,75.8l4.6-11.9h1.7l4.9,11.9H232l-1.4-3.6h-5l-1.3,3.6H222.6z M226.1,70.9h4l-1.2-3.3
	c-0.4-1-0.7-1.8-0.9-2.5c-0.2,0.8-0.4,1.5-0.6,2.3L226.1,70.9z"
    />
    <path
      css={st0}
      d="M240.2,75.8V63.9h5.3c0.8,0,1.6,0.1,2.4,0.3c0.6,0.2,1,0.6,1.3,1.1c0.3,0.5,0.5,1.2,0.5,1.8
	c0,0.8-0.3,1.6-0.8,2.1c-0.7,0.6-1.6,1-2.5,1.1c0.4,0.1,0.7,0.3,1,0.6c0.5,0.5,0.9,1,1.3,1.6l2.1,3.2h-2l-1.6-2.5
	c-0.5-0.7-0.8-1.3-1.1-1.6c-0.2-0.3-0.5-0.6-0.8-0.8c-0.2-0.1-0.5-0.2-0.7-0.3c-0.3,0-0.6-0.1-0.9-0.1h-1.8v5.3L240.2,75.8z
	 M241.8,69.1h3.4c0.6,0,1.1-0.1,1.7-0.2c0.4-0.1,0.7-0.4,0.9-0.7c0.2-0.3,0.3-0.7,0.3-1.1c0-0.5-0.2-1-0.6-1.4
	c-0.6-0.4-1.2-0.6-1.9-0.5h-3.8L241.8,69.1z"
    />
    <path css={st0} d="M260.4,75.8V65.3h-3.9v-1.4h9.4v1.4H262v10.5H260.4z" />
    <path css={st0} d="M272.6,75.8V63.9h1.6l6.3,9.4v-9.4h1.5v11.9h-1.6l-6.3-9.4v9.4H272.6z" />
    <path css={st0} d="M289.8,75.8V63.9h8.6v1.4h-7v3.6h6.6v1.4h-6.6v4.1h7.3v1.4L289.8,75.8z" />
    <path
      css={st0}
      d="M306,75.8V63.9h5.3c0.8,0,1.6,0.1,2.4,0.3c0.6,0.2,1,0.6,1.3,1.1c0.3,0.5,0.5,1.2,0.5,1.8
	c0,0.8-0.3,1.6-0.8,2.1c-0.7,0.6-1.6,1-2.5,1.1c0.4,0.1,0.7,0.3,1,0.6c0.5,0.5,0.9,1,1.3,1.6l2.1,3.2h-2l-1.5-2.5
	c-0.5-0.7-0.8-1.3-1.1-1.6c-0.2-0.3-0.5-0.6-0.8-0.8c-0.2-0.1-0.5-0.2-0.7-0.3c-0.3,0-0.6-0.1-0.9-0.1h-1.8v5.3H306z M307.5,69.1
	h3.4c0.6,0,1.1-0.1,1.7-0.2c0.4-0.1,0.7-0.4,0.9-0.7c0.2-0.3,0.3-0.7,0.3-1.1c0-0.5-0.2-1-0.6-1.4c-0.6-0.4-1.2-0.6-1.9-0.5h-3.8
	L307.5,69.1z"
    />
    <path
      css={st0}
      d="M331.6,70c-0.1-1.7,0.5-3.4,1.6-4.6c1.1-1.1,2.6-1.7,4.1-1.7c1.1,0,2.1,0.3,3,0.8c0.9,0.5,1.6,1.3,2,2.2
	c0.5,1,0.7,2.1,0.7,3.2c0,1.1-0.2,2.2-0.7,3.2c-0.5,0.9-1.2,1.7-2.1,2.2c-0.9,0.5-1.9,0.7-2.9,0.7c-1.1,0-2.1-0.3-3-0.8
	c-0.9-0.5-1.6-1.3-2-2.2C331.9,72.1,331.6,71,331.6,70z M333.3,70c-0.1,1.2,0.3,2.5,1.2,3.4c1.5,1.6,4,1.7,5.7,0.2
	c0.1-0.1,0.1-0.1,0.2-0.2c0.8-1,1.2-2.3,1.2-3.5c0-0.9-0.1-1.7-0.5-2.5c-0.3-0.7-0.8-1.3-1.4-1.7c-0.6-0.4-1.4-0.6-2.1-0.6
	c-1.1,0-2.1,0.4-2.9,1.1C333.6,67.2,333.1,68.6,333.3,70L333.3,70z"
    />
    <path css={st0} d="M350.3,75.8V63.9h8v1.4h-6.5V69h5.6v1.4h-5.6v5.4L350.3,75.8z" />
    <path css={st4} d="M28.5,0v42.7c0,0,0,4.7,4.8,4.7H76V0H28.5L28.5,0z" />
  </svg>
)

export default LaempeLogo
