export interface Locale {
  locale: string
  language: string
  countryCode: string
  variant?: string
}

export const defaultLocale: Locale = {
  locale: 'de_DE',
  language: 'de',
  countryCode: 'DE',
}

export type Messages = Record<string, string>
export type MessagesByLanguage = Record<string, Messages>

export const currencyByCountry: Record<string, string> = {
  DE: 'EUR',
  AT: 'EUR',
  FR: 'EUR',
  ES: 'EUR',
  HU: 'HUF',
}
